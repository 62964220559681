import React from 'react'
import { Link } from 'gatsby'
import shape1 from '../../assets/images/shape/shape1.svg'
import shape2 from '../../assets/images/shape/shape2.png'
import shape3 from '../../assets/images/shape/shape3.png'
import VideoBg from '../../assets/images/Homepage.mp4';

const PageBanner = ({ pageTitle, homePageUrl, homePageText, activePageText }) => {
    return (
        <>
        <video className='bg-vid' autoPlay loop muted>
            <source src={VideoBg} type='video/mp4' />
        </video>
     
        {/*<div className="page-title-area-fleet">
            <div className="container">
                <div className="page-title-content">
                    <h2>{pageTitle}</h2>
                    <ul>
                        <li>
                            <Link to={homePageUrl}>
                                {homePageText}
                            </Link>
                        </li>
                        <li>{activePageText}</li>
                    </ul>
                </div>
           </div>
    </div>*/}

    </>
    )
}

export default PageBanner;